/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useSearchParams, useParams } from 'react-router-dom';
import Filter from '../components/filter';
import ProductList from '../components/product_list';
import Pagination from '../components/pagination';
import { getAllProducts } from '../constants/firebaseConfig';
import PreselectedFilter from '../constants/preselected_filter';

function parseText(text) {
  const words = text.split('-');
  const parsedText = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

  return parsedText;
}

function ProductPage() {
  const { productType } = useParams();
  const [currentFilter, setCurrentFilter] = useState({
    brand: '',
    shape: '',
    type: '',
    color: '',
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const itemsPerPage = 10;

  useEffect(() => {
    // Filter products based on currentFilter state
    const filtered = products.filter((product) => {
      return (
        (currentFilter.brand ? product.brand === currentFilter.brand : true)
                && (currentFilter.color ? product.color === currentFilter.color : true)
                && (currentFilter.type ? product.type === currentFilter.type : true)
                && (currentFilter.shape ? product.shape === currentFilter.shape : true)
      );
    });
    setFilteredProducts(filtered);
    setCurrentPage(1); // Reset to first page after applying new filter
  }, [currentFilter, products]);

  useEffect(() => {
  // Fetch products from API
    const fetchProducts = async () => {
      try {
        const fetchedProducts = await getAllProducts();
        setProducts(fetchedProducts);
        setCurrentFilter(PreselectedFilter[productType]);
      } catch (error) {
        console.error('Error fetching products:', error);
      // Handle error appropriately
      }
    };
    fetchProducts();
  }, [productType]); // Adding productType as a dependency

  // Get current products for the page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredProducts.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);
  const handleFilterChange = (newFilter) => setCurrentFilter(newFilter);

  const productTitle = parseText(productType);
  return (
    <div className="product-page-container">
      <h1>{productTitle}</h1>
      <div className="product-page">
        <div className="product_list_filter_container">
          <Filter currentFilter={currentFilter} onChange={handleFilterChange} />
        </div>
        <div className="product_list_list_container">
          <ProductList products={currentItems} />
          <Pagination
            itemsPerPage={itemsPerPage}
            totalItems={filteredProducts.length}
            paginate={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
}

export default ProductPage;
