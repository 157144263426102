import React, { useState, useEffect } from 'react';

function Partners() {
  const [imageList, setImageList] = useState([]);

  useEffect(() => {
    // Import images dynamically from the ./assets/partners folder
    const importImages = (r) => r.keys().map(r);
    const images = importImages(require.context('../assets/partner_logos', false, /\.(png|jpe?g|svg)$/));

    setImageList(images);
  }, []);

  return (
    <div className="image-list">
      {imageList.map((image, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <img key={`partner${index}`} className="partner-image" src={image} alt={`Partner ${index + 1}`} />
      ))}
    </div>
  );
}

export default Partners;
