/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getOrderById, updateOrderStatus } from '../constants/firebaseConfig';
import CartThumbnail from '../components/cart_thumbnail';

function OrderDetail() {
  const { orderId } = useParams();
  const navigate = useNavigate();
  const [address, setAddress] = useState({
    city: '',
    country: '',
    line1: '',
    line2: '',
    state: '',
    zipCode: '',
  });
  const [cartItems, setCartItems] = useState([]);
  const [orderStatus, setOrderStatus] = useState('');
  const [total, setTotal] = useState(0);

  useEffect(() => {
    getOrderById(orderId).then((order) => {
      setAddress({
        city: order.address.city,
        country: order.address.country,
        line1: order.address.line1,
        line2: order.address.line2,
        state: order.address.state,
        zipCode: order.address.zipCode,
      });
      setCartItems(order.cartItems);
      setOrderStatus(order.status);
      setTotal(order.total);
    });
  }, [orderStatus]);

  const handleCancelOrder = async () => {
    console.log('Canceling order...');
    updateOrderStatus(orderId, 'Cancelled').then(() => {
      setOrderStatus('Cancelled');
    });
    navigate(-1);
    // Add logic to handle order cancellation
  };

  return (
    <div className="order-detail">
      <div className="order-header">
        <div className="order-address">
          <p>{address.line1}</p>
          <p>{address.line2}</p>
          <p>{address.city}, {address.state}</p>
          <p>{address.country}</p>
          <p>{address.zipCode}</p>
        </div>
        <div className="order-status">
          <p>Status: {orderStatus}</p>
        </div>
      </div>

      <div className="order-items">
        {cartItems.map((item, _) => (
          <CartThumbnail key={item.documentId} item={item} />
        ))}
      </div>

      <div className="order-total">
        Total: ${total}
      </div>

      <button type="button" onClick={handleCancelOrder} className="cancel-order-button">Cancel Order</button>
    </div>
  );
}

export default OrderDetail;
