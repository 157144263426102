import React from 'react';
import ProductThumbnail from './product_thumbnail';

function ProductList({ products }) {
  return (
    <div className="product-list">
      {products.map((product) => (
        <ProductThumbnail key={product.productId} product={product} />
      ))}
    </div>
  );
}

export default ProductList;
