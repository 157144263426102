import React from 'react';

const tradeshowData = [
  {
    id: 1,
    imageUrl: 'https://surfexpo.com/wp-content/uploads/2023/09/J24_SurfExpo_Lockup_website_590.png',
    dateFrom: '2024-01-10',
    dateTo: '2023-01-12',
    boothNumber: '1785',
    location: 'Orlando, FL',
  },
  // Add more tradeshow objects as needed
];

function Tradeshow() {
  return (
    <div className="tradeshow-page">
      <h2>Tradeshows</h2>
      <p>We are excited to announce that we will be attending several tradeshows this year! Check out our booth at the following events:</p>
      <div className="tradeshow-list">
        {tradeshowData.map((tradeshow) => (
          <div key={tradeshow.id} className="tradeshow-item">
            <img src={tradeshow.imageUrl} alt={`Tradeshow ${tradeshow.id}`} />
            <div className="tradeshow-info">
              <p>Date: {tradeshow.dateFrom} to {tradeshow.dateTo}</p>
              <p>Booth: {tradeshow.boothNumber}</p>
              <p>Location: {tradeshow.location}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Tradeshow;
