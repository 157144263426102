/* eslint-disable no-unused-vars */
import React from 'react';
import { useNavigate } from 'react-router';
import BannerImage from '../assets/img/landing_page_background.png';
import Partners from '../components/partners';
import CompanySection from '../components/company';
import ProcessSection from '../components/process';

function HomePage() {
  const navigate = useNavigate();

  const handleLearnMore = () => {
    navigate('/products/all-products');
  };

  return (
    <div className="home_container">
      <div className="banner_container">
        {/* <div className="banner">
          <img className="full-height" src={BannerImage} alt="Banner" />
        </div> */}
        <div className="home_title">
          <h1>Elevate Your Boutique</h1>
          <h2>Glass Beads Jewelry</h2>
          <div className="home_button_container">
            <button
              type="button"
              className="home_button"
              onClick={handleLearnMore}
            >Learn More
            </button>
          </div>
        </div>
      </div>
      <div className="section_container vertical_section_container">
        <h2>Our Partners</h2>
        <Partners />
      </div>
      <div className="section_container grey_section_container">
        <CompanySection />
      </div>
      <div className="section_container horizontal_section_container">
        <ProcessSection />
      </div>
    </div>
  );
}

export default HomePage;
