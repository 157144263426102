/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useRef } from 'react';
import Colors from '../constants/colors';
import Types from '../constants/types';
import Shapes from '../constants/shapes';
import Brands from '../constants/brands';

function Filter({ currentFilter, onChange }) {
  const defaultFilterState = {
    brand: '',
    color: '',
    type: '',
    shape: '',
    // Add other fields as needed
  };
  const [filter, setFilter] = useState(currentFilter);
  // const [priceRange, setPriceRange] = useState({ min: 0, max: 1000 });

  useEffect(() => {
    setFilter(currentFilter);
  }, [currentFilter]);

  // const handlePriceChange = (event) => {
  //   setPriceRange({ ...priceRange, [event.target.name]: event.target.value });
  // };

  const handleFilterChange = (event) => {
    setFilter({ ...filter, [event.target.name]: event.target.value });
  };

  const applyFilter = () => {
    onChange(filter);
  };

  const clearFilter = () => {
    setFilter(defaultFilterState);
    onChange(defaultFilterState);
  };

  return (
    <div className="filter">
      <h3 className="filter-title">Filter Products</h3>
      <div className="filter-option">
        <label>Brand:</label>
        <select value={filter.brand} onChange={handleFilterChange} name="brand">
          <option value="" disabled>Select a brand</option>
          {Brands.map((brand, index) => (
            <option key={brand} value={brand}>
              {brand}
            </option>
          ))}
        </select>
      </div>
      <div className="filter-option">
        <label>Color:</label>
        <select value={filter.color} onChange={handleFilterChange} name="color">
          <option value="" disabled>Select a color</option>
          {Colors.map((color, index) => (
            <option key={color} value={color}>
              {color}
            </option>
          ))}
        </select>
      </div>
      <div className="filter-option">
        <label>Type:</label>
        <select value={filter.type} onChange={handleFilterChange} name="type">
          <option value="" disabled>Select a type</option>
          {Types.map((type, index) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </select>
      </div>
      <div className="filter-option">
        <label>Shape:</label>
        <select value={filter.shape} onChange={handleFilterChange} name="shape">
          <option value="" disabled>Select a shape</option>
          {Shapes.map((shape, index) => (
            <option key={shape} value={shape}>
              {shape}
            </option>
          ))}
        </select>
      </div>
      {/* <div className="filter-option">
        <label>Price Range:</label>
        <input type="number" name="min" value={priceRange.min} onChange={handlePriceChange} />
        <input type="number" name="max" value={priceRange.max} onChange={handlePriceChange} />
      </div> */}
      <div className="filter-button-container">
        <button className="filter-button" type="button" onClick={applyFilter}>Apply</button>
        <button className="filter-button-cancel" type="button" onClick={clearFilter}>Clear</button>
      </div>
    </div>
  );
}

export default Filter;
