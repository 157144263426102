/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import CheckoutForm from '../components/checkout_form';

// Replace with your test publishable API key
// const stripePromise = loadStripe('pk_test_51ObGMnJeayonKzdeP5RipVK9V67OtUpezptboJqGyw3h3CR49spPKHMaMe721916ZY2Kg21SJYKVWktkuDNapjlc00nIIjkq7F');
const stripePromise = loadStripe('pk_live_51ObGMnJeayonKzdexjfmthFQliJtVjeLxWWyATuoGWUhR7Xtx4oR1NgygdVo2sOhNGt5o2UnDERnqQXKJ3ydSfu000IQXlubv1');
// const serverUrl = 'https://wholesale-stripe-server.onrender.com';
// const serverUrl = 'https://wholesale-stripe-server.web.app';
// const serverUrl = 'http://127.0.0.1:5001/wholesalestripefunction/us-central1';
const serverUrl = 'https://us-central1-wholesalestripefunction.cloudfunctions.net';
// const serverUrl = 'http://localhost:9090';

export default function Payment() {
  const [clientSecret, setClientSecret] = useState('');
  const [paymentStatus, setPaymentStatus] = useState(false);
  const cartItems = useSelector((state) => state.cart.cartItems);
  const { state } = useLocation();
  const { totalAmount, orderId } = state;

  useEffect(() => {
    // Check if cartItems is defined and not empty before making the fetch request
    if (cartItems && cartItems.length > 0) {
      // fetch(`${serverUrl}/create-payment-intent`, {
      fetch(`${serverUrl}/createPaymentIntent`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify(cartItems),
      })
        .then((res) => res.json())
        .then((data) => setClientSecret(data.clientSecret));
    }
  }, [cartItems]);

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div className="payment_container">
      {paymentStatus ? (
      // Render this when paymentStatus is true (payment successful)
        <div className="payment_status_container">
          <div className="payment_checkmark">✓</div>
          <h1>Payment Successful</h1>
          <p>Thank you for your purchase!</p>
        </div>
      ) : (
      // Render this when paymentStatus is false (payment information)
        <>
          <div className="payment_title">Payment Information</div>
          <div className="payment_total_amount">
            Total: ${totalAmount}
          </div>
          {clientSecret && (
          <Elements options={options} stripe={stripePromise}>
            <CheckoutForm setPaymentStatus={setPaymentStatus} orderId={orderId} />
          </Elements>
          )}
        </>
      )}
    </div>

  );
}
