/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/button-has-type */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Hamburger from '../assets/img/hamburger.png';
import '../styles/nav.scss';

function NavBar() {
  const [miyukiDropdown, setMiyukiDropdown] = useState(false);
  const [preciosaDropdown, setPreciosaDropdown] = useState(false);
  const [londonderryDropdown, setLondonderryDropdown] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const user = useSelector((state) => state.user.currentUser);

  return (
    <nav className="nav_bar">
      <div className="hamburger_container" onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
        {mobileMenuOpen ? (
          <div className="nav_close">&#215;</div>
        ) : (
          <img className="hamburger" src={Hamburger} alt="Menu" />
        )}
      </div>
      <div className={`nav_menu ${mobileMenuOpen ? 'open' : ''}`}>
        <div className="nav_left">
          <Link className="nav_link" to="/">Home</Link>
          <div
            className="dropdown"
            onMouseEnter={() => setMiyukiDropdown(true)}
            onMouseLeave={() => setMiyukiDropdown(false)}
          >
            <button className="dropbtn">Miyuki</button>
            {miyukiDropdown && (
            <div className="dropdown-content">
              <Link className="nav_link" to="/products/miyuki-tila-bead-bracelet">Tila Bead Bracelet</Link>
              <Link className="nav_link" to="/products/miyuki-seed-bead-bracelet">Seed Bead Bracelet</Link>
              <Link className="nav_link" to="/products/miyuki-earring">Earring</Link>
              <Link className="nav_link" to="/products/miyuki-necklace">Necklace</Link>
            </div>
            )}
          </div>

          <div
            className="dropdown"
            onMouseEnter={() => setPreciosaDropdown(true)}
            onMouseLeave={() => setPreciosaDropdown(false)}
          >
            <button className="dropbtn">Preciosa</button>
            {preciosaDropdown && (
            <div className="dropdown-content">
              <Link className="nav_link" to="/products/preciosa-seed-bead-bracelet">Seed Bead Bracelet</Link>
              <Link className="nav_link" to="/products/preciosa-tcb-bracelet">Table Pressed Bracelet</Link>
              <Link className="nav_link" to="/products/preciosa-earring">Earring</Link>
              <Link className="nav_link" to="/products/preciosa-necklace">Necklace</Link>
              {/* Add more links as needed */}
            </div>
            )}
          </div>

          <div
            className="dropdown"
            onMouseEnter={() => setLondonderryDropdown(true)}
            onMouseLeave={() => setLondonderryDropdown(false)}
          >
            <button className="dropbtn">Londonderry</button>
            {londonderryDropdown && (
            <div className="dropdown-content">
              <Link className="nav_link" to="/products/londonderry-bracelet">Bracelet</Link>
              <Link className="nav_link" to="/products/londonderry-earring">Earring</Link>
              <Link className="nav_link" to="/products/londonderry-necklace">Necklace</Link>
              {/* Add more links as needed */}
            </div>
            )}
          </div>

          <Link className="nav_link" to="/combo">Tila Combo Set</Link>
          <Link className="nav_link" to="/tradeshow">Tradeshows</Link>
          <Link className="nav_link" to="/about">About</Link>
        </div>

        <div className="nav_right">
          {user ? (
            <Link className="nav_link" to="/account">Account</Link>
          ) : (
            <Link className="nav_link" to="/login">Login</Link>
          )}
        </div>
      </div>
    </nav>
  );
}

export default NavBar;
