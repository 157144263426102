/* eslint-disable no-unused-vars, no-undef */
// eslint-disable-next-line no-multiple-empty-lines
import React from 'react';
import '../style.scss';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from '../pages/home';
import About from '../pages/about';
import Login from '../pages/login';
import LandingPage from '../pages/landing_page';
import Tradeshow from '../pages/tradeshow';
import NavBar from '../pages/nav';
import Footer from '../pages/footer';
import ProductPage from '../pages/product_list_page';
import Register from '../pages/register';
import MyAccount from '../pages/myaccount';
import Comfirmation from '../pages/confirmation';
import OrderDetail from '../pages/order_detail';
import ComboPage from '../pages/combo';
import Payment from '../pages/payment';

function FallBack(props) {
  return <div>URL Not Found</div>;
}

function App() {
  return (
    <Router>
      <div>
        <NavBar />
        <Routes>
          <Route path="/" exact element={<HomePage />} />
          <Route path="/products/:productType" element={<ProductPage />} />
          <Route path="/tradeshow" element={<Tradeshow />} />
          <Route path="/about" element={<About />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/account" element={<MyAccount />} />
          <Route path="/confirmation" element={<Comfirmation />} />
          <Route path="/orders/:orderId" element={<OrderDetail />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/combo" element={<ComboPage />} />
          <Route path="*" element={<FallBack />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

// function App(props) {
//   return (
//     <LandingPage />
//     // <Router>
//     //   <div>
//     //     <Nav />
//     //     <Routes>
//     //       <Route path="/" element={<Welcome />} />
//     //       <Route path="/about" element={<About />} />
//     //       <Route path="/test/:id" element={<Test />} />
//     //       <Route path="*" element={<FallBack />} />
//     //     </Routes>
//     //   </div>
//     // </Router>
//   );
// }

export default App;
