/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';
import {
  getFirestore, collection, addDoc, doc, setDoc, arrayUnion, getDoc, getDocs, updateDoc, query, where, serverTimestamp,
} from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAzWqrGkN1-tLxD4S-FKuWztIVYTdZ1TMY',
  authDomain: 'wholesalewebsite-5e4dc.firebaseapp.com',
  projectId: 'wholesalewebsite-5e4dc',
  storageBucket: 'wholesalewebsite-5e4dc.appspot.com',
  messagingSenderId: '657372574437',
  appId: '1:657372574437:web:6319130384fd2fa545da0c',
  measurementId: 'G-HV2K3SQJCL',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export async function registerUser(email, password, companyName) {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    // Signed in
    const { user } = userCredential;
    console.log('User registered successfully');

    try {
      const docRef = await addDoc(collection(db, 'clients'), {
        email,
        companyName,
      });
      console.log('Document written with ID: ', docRef.id);
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  } catch (error) {
    console.error('Error registering user: ', error);
  }
}

export async function signInUser(email, password) {
  signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // Signed in
      const { user } = userCredential;
      console.log('User logged in successfully');
      // navigate('/');
      // ...
    }).catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log('Error in logging in', errorCode, errorMessage);
      alert(errorMessage);
    });
}

export const sighOutUser = () => {
  if (!auth.currentUser) {
    console.log('No user logged in');
    return;
  }
  auth.signOut().then(() => {
    // Sign-out successful.
    console.log('User signed out successfully');
    // navigate('/');
  }).catch((error) => {
    // An error happened.
    console.log('Error in signing out', error);
  });
};

export const fetchCombo = async (productId) => {
  try {
    const q = query(collection(db, 'products'), where('productId', '==', productId));

    const docSnap = await getDocs(q);
    const comboItems = [];

    if (docSnap) {
      docSnap.forEach((document) => {
        console.log('Document data:', document.data());
        comboItems.push(document.data());
      });
      return comboItems;
    } else {
      // doc.data() will be undefined in this case
      console.log('No such document!');
      throw new Error('No such document!');
    }
  } catch (error) {
    console.error('Error fetching combo:', error);
    throw error;
  }
};

// add order item to cart
export const addToCart = async (userId, productId, quantity, size, price, imageUrl, type) => {
  const cartRef = doc(db, 'carts', userId); // Each user has a unique cart document

  const cartItem = {
    productId,
    quantity,
    size,
    price,
    imageUrl,
    type,
  };

  try {
    if (!cartRef) {
      return;
    }
    const docSnap = await getDoc(cartRef);

    if (docSnap.exists()) {
      // If the document exists, use updateDoc to add the cart item
      const cartItems = docSnap.data().cartItems || [];
      const productIndex = cartItems.findIndex((item) => item.productId === productId && item.size === size);

      if (productIndex > -1) {
        // Update the existing product quantity and other details
        cartItems[productIndex].quantity += quantity; // Update quantity
        cartItems[productIndex].size = size; // Update size if needed
        cartItems[productIndex].price = price; // Update price if needed
      } else {
        // Add new product to cart
        cartItems.push({
          productId, quantity, size, price, imageUrl, type,
        });
      }
      await updateDoc(cartRef, { cartItems });
    } else {
      // If the document does not exist, use setDoc to create it
      await setDoc(cartRef, {
        cartItems: [cartItem], // Initialize cartItems as an array with the first item
      });
    }
  } catch (error) {
    console.error('Error adding item to cart: ', error);
  }
};

// Function to remove a single item from the cart
export const removeItemFromCart = async (productId, size) => {
  try {
    const { currentUser } = auth;
    if (!currentUser) throw new Error('No user logged in');
    const userId = currentUser.uid;
    const cartRef = doc(db, 'carts', userId);
    const docSnap = await getDoc(cartRef);

    if (!docSnap.exists()) {
      console.log('No cart found for the user.');
      return;
    }

    const existingCartItems = docSnap.data().cartItems || [];
    const updatedCartItems = existingCartItems.filter((item) => !(item.productId === productId && item.size === size));

    await updateDoc(cartRef, {
      cartItems: updatedCartItems,
    });

    console.log('Item removed from cart');
  } catch (error) {
    console.error('Error removing item from cart: ', error);
  }
};

export const fetchCartItems = async () => {
  try {
    const { currentUser } = auth;
    if (!currentUser) throw new Error('No user logged in');

    const cartRef = doc(db, 'carts', currentUser.uid);
    const docSnap = await getDoc(cartRef);

    const cartItems = [];

    if (docSnap) {
      docSnap.data().cartItems.forEach((item) => {
        cartItems.push(item);
      });
    }
    console.log('Cart items: ', cartItems);
    return cartItems;
  } catch (error) {
    console.error('Error fetching cart items:', error);
    throw error; // Rethrow the error to handle it in the component
  }
};

// Function to clear the cart
export const clearCart = async (userId) => {
  const cartRef = doc(db, 'carts', userId);

  try {
    await setDoc(cartRef, {
      cartItems: [], // Set cartItems to an empty array
    });
    console.log('Cart cleared');
  } catch (error) {
    console.error('Error clearing cart: ', error);
  }
};

export const getAllProducts = async () => {
  try {
    const docRef = collection(db, 'products');
    const docSnap = await getDocs(docRef);
    const products = [];

    if (docSnap) {
      docSnap.forEach((document) => {
        products.push(document.data());
      });
      return products;
    } else {
      // docSnap.data() will be undefined in this case
      console.log('No such document!');
      throw new Error('No such document!');
    }
  } catch (error) {
    console.error('Error fetching products: ', error);
    throw error;
  }
};

export const submitOrder = async (cartItems, address, total, note) => {
  try {
    const { currentUser } = auth;
    if (!currentUser) throw new Error('No user logged in');

    // Create a new order object
    const order = {
      userId: currentUser.uid,
      cartItems,
      address,
      total,
      orderDate: serverTimestamp(), // To capture the order date and time
      status: 'Order Placed', // To track the order status
      note,
    };

    const orderRef = await addDoc(collection(db, 'orders'), order);
    await updateDoc(doc(db, 'orders', orderRef.id), { documentId: orderRef.id });
    await clearCart(currentUser.uid); // Clear the cart after the order is placed
    return orderRef.id; // Return the order ID for reference if needed
  } catch (error) {
    console.error('Error submitting order:', error);
    throw error;
  }
};

export const fetchOrders = async () => {
  try {
    const { currentUser } = auth;
    if (!currentUser) throw new Error('No user logged in');

    const q = query(collection(db, 'orders'), where('userId', '==', currentUser.uid));

    const docSnap = await getDocs(q);
    // const docSnap = await getDocs(q);
    // console.log('docsnap is ', docSnap);
    const orderItems = [];

    if (docSnap) {
      docSnap.forEach((item) => {
        orderItems.push(item.data());
      });
    }
    console.log('Cart items: ', orderItems);
    return orderItems;
  } catch (error) {
    console.error('Error fetching cart items:', error);
    throw error; // Rethrow the error to handle it in the component
  }
};

export const getOrderById = async (orderId) => {
  try {
    const docRef = doc(db, 'orders', orderId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      console.log('Document data:', docSnap.data());
      return docSnap.data();
    } else {
      // doc.data() will be undefined in this case
      console.log('No such document!');
      throw new Error('No such document!');
    }
  } catch (error) {
    console.error('Error fetching order:', error);
    throw error;
  }
};

export const updateOrderStatus = async (orderId, status) => {
  try {
    const docRef = doc(db, 'orders', orderId);

    await updateDoc(docRef, {
      status,
    });
  } catch (error) {
    console.error('Error updating order status:', error);
    throw error;
  }
};
