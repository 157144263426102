import React from 'react';
import { Link } from 'react-router-dom';

function OrderThumbnail({ order }) {
  const date = new Date(order.orderDate.seconds * 1000);
  const displayDate = date.toLocaleDateString('en-US', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  return (
    <Link to={`/orders/${order.documentId}`} className="order-thumbnail">
      <div className="order-detail">Order #: {order.documentId}</div>
      <div className="order-detail">Date: {displayDate}</div>
      <div className="order-detail">Total: ${Number(order.total).toFixed(2)}</div>
      <div className="order-detail">Status: {order.status}</div>
    </Link>
  );
}

export default OrderThumbnail;
