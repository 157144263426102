// keys for actiontypes
export const ActionTypes = {
  SET_USER: 'SET_USER',
  CLEAR_USER: 'CLEAR_USER',
  SET_CART_ITEMS: 'SET_CART_ITEMS',
  REMOVE_ITEM_FROM_CART: 'REMOVE_ITEM_FROM_CART',
};

export const setUser = (user) => {
  return {
    type: 'SET_USER',
    payload: user,
  };
};

export const clearUser = () => {
  return {
    type: 'CLEAR_USER',
  };
};

export const setCart = (cartItems) => {
  return {
    type: 'SET_CART_ITEMS',
    payload: cartItems,
  };
};

export const removeItemFromCartAction = (productId, size) => {
  return {
    type: 'REMOVE_ITEM_FROM_CART',
    payload: { productId, size },
  };
};
