/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
// import { auth } from '../constants/firebaseConfig'; // Import Firebase auth
import { getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { registerUser } from '../constants/firebaseConfig';
import { setUser } from '../actions';

function Register() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [username, setUsername] = useState('');
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const validate = () => {
    const tempErrors = {};
    if (!username) {
      tempErrors.username = 'Username is required';
    }
    if (!email) {
      tempErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      tempErrors.email = 'Email is not valid';
    }
    if (!password) {
      tempErrors.password = 'Password is required';
    } else if (password.length < 6) {
      tempErrors.password = 'Password must be at least 6 characters';
    }
    if (!companyName) {
      tempErrors.companyName = 'Company name is required';
    }
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      if (validate()) {
        await registerUser(email, password, companyName);
        const auth = getAuth();
        const user = auth.currentUser.email;
        if (user) {
          dispatch(setUser(user));
          navigate('/');
        }
        // dispatch(setUser(auth.currentUser));
      }
    } catch (error) {
      console.error('Error in signing up', error);
    }
  };

  return (
    <div className="auth-form">
      <h2>Register</h2>
      <form onSubmit={handleRegister}>
        <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} placeholder="Username" />
        {errors.username && <div className="error">{errors.username}</div>}
        <input type="text" value={companyName} onChange={(e) => setCompanyName(e.target.value)} placeholder="Company Name" />
        {errors.companyName && <div className="error">{errors.companyName}</div>}
        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email Address" />
        {errors.email && <div className="error">{errors.email}</div>}
        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" />
        {errors.password && <div className="error">{errors.password}</div>}
        <button type="submit">Register</button>
      </form>
    </div>
  );
}

export default Register;
