/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import CartThumbnail from '../components/cart_thumbnail';
import { submitOrder } from '../constants/firebaseConfig';
import calculateShipping from '../utils/shipping_cal';

function Confirmation() {
  const [address, setAddress] = useState({
    line1: '',
    line2: '',
    city: '',
    state: '',
    country: '',
    zipCode: '',
  });
  const [cartTotal, setTotal] = useState(0);
  const [shippingCost, setShippingCost] = useState(0);
  const [additionalNotes, setAdditionalNotes] = useState('');
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const cartItems = useSelector((state) => state.cart.cartItems);

  const navigate = useNavigate();
  const getTotal = () => {
    return cartItems.reduce((total, item) => total + (item.price * item.quantity), 0).toFixed(2);
  };

  useEffect(() => {
    setTotal(getTotal());
    setShippingCost(calculateShipping(cartItems));
  }, [cartItems]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAddress((prevAddress) => ({
      ...prevAddress,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (e) => {
    setIsCheckboxChecked(e.target.checked);
  };

  const handleConfirmOrder = async () => {
    console.log('Confirming order with address:', address);
    const totalAmount = Number(cartTotal) + Number(shippingCost);
    const orderId = await submitOrder(cartItems, address, totalAmount, additionalNotes);
    console.log('Order confirmed with ID:', orderId);
    navigate('/payment', { state: { totalAmount: totalAmount.toFixed(2), orderId } });
  };

  const onRemove = () => {
    alert('Please return to the cart to delete this product.');
  };

  return (
    <div className="order-confirmation">
      <div className="cart-items">
        {cartItems.map((item, index) => (
          <CartThumbnail key={item.productId} item={item} onRemove={onRemove} />
        ))}

        <div className="confirmation-total">
          Total: ${cartTotal}
        </div>

        <div className="confirmation-shipping">
          Shipping: ${shippingCost}
        </div>

      </div>

      <div className="confirmation-address-container">
        <div className="address-form">
          <input
            name="line1"
            value={address.line1}
            onChange={handleInputChange}
            placeholder="Address Line 1"
          />
          <input
            name="line2"
            value={address.line2}
            onChange={handleInputChange}
            placeholder="Address Line 2"
          />
          <input
            name="city"
            value={address.city}
            onChange={handleInputChange}
            placeholder="City"
          />
          <input
            name="state"
            value={address.state}
            onChange={handleInputChange}
            placeholder="State"
          />
          <input
            name="country"
            value={address.country}
            onChange={handleInputChange}
            placeholder="Country"
          />
          <input
            name="zipCode"
            value={address.zipCode}
            onChange={handleInputChange}
            placeholder="Zip Code"
          />
        </div>
        <textarea
          className="additional-notes"
          value={additionalNotes}
          onChange={(e) => setAdditionalNotes(e.target.value)}
          placeholder="Add any additional notes here"
        />
        <p className="order-info-paragraph">
          Because all products are customized and we offer favorable prices directly from the factory,
          products will be wrapped individually in plastic jewelry bags unless otherwise stated for an
          additional cost. Refunds or rebates are only available if the quantity of the product is incorrect,
          or if you receive a damaged product, or when Londonderry Fashion deems it necessary. Any other
          reasons not related to the product specification itself will not be eligible for a refund.
        </p>

        <div className="confirm-checkbox">
          <input
            type="checkbox"
            id="confirmCheckbox"
            checked={isCheckboxChecked}
            onChange={handleCheckboxChange}
          />
          <label htmlFor="confirmCheckbox">I agree to the terms and conditions</label>
        </div>
      </div>

      {/* {clientSecret && (
      <div className="payment-section">
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm setPaymentStatus={setPaymentStatus} />
        </Elements>
      </div>
      )} */}

      <button
        type="submit"
        onClick={handleConfirmOrder}
        className="confirm-button"
        disabled={!isCheckboxChecked}
      >Proceed to Payment
      </button>
    </div>
  );
}

export default Confirmation;
