/* eslint-disable no-unused-vars */
/* eslint-disable react/button-has-type */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import CartThumbnail from '../components/cart_thumbnail';
import { fetchCartItems, removeItemFromCart } from '../constants/firebaseConfig';
import { setCart, removeItemFromCartAction } from '../actions';

function MyCart() {
  // Example cart items (replace with your actual cart items logic)
  const [cartItems, setCartItems] = useState([]);
  const [total, setTotal] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    fetchCartItems().then((items) => {
      setCartItems(items);
      dispatch(setCart(items));
    });
  }, []); //

  useEffect(() => {
    let totalCost = 0;
    cartItems.forEach((item) => {
      totalCost += item.price * item.quantity;
    });

    setTotal(totalCost.toFixed(2));
  }, [cartItems]);

  const handleRemoveItem = async (productId, size) => {
    try {
      await removeItemFromCart(productId, size);
      const updatedItems = cartItems.filter((item) => !(item.productId === productId && item.size === size));
      setCartItems(updatedItems);
      dispatch(removeItemFromCartAction(productId, size));
    } catch (error) {
      console.error('Error removing item from cart:', error);
    }
  };

  const handleButtonClick = () => {
    navigate('/confirmation');
  };

  return (
    <div>
      {cartItems.map((item) => (
        <CartThumbnail key={item.productId} item={item} onRemove={handleRemoveItem} />
      ))}
      {/* Total and Place Order Button */}
      <div className="cart_summary_container">
        <p>Total: {total}</p>
        <button className="cart_button" type="submit" onClick={handleButtonClick}>Place Order</button>
      </div>
    </div>
  );
}

export default MyCart;
