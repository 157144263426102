const preselectedFilter = {
  'all-products':
  {
    brand: '',
    shape: '',
    type: '',
    color: '',
  },
  'miyuki-tila-bead-bracelet': {
    brand: 'Miyuki',
    shape: 'Tila',
    type: 'Bracelet',
    color: '',
  },
  'miyuki-seed-bead-bracelet': {
    brand: 'Miyuki',
    shape: 'Seed Bead',
    type: 'Bracelet',
    color: '',
  },
  'miyuki-earring': {
    brand: 'Miyuki',
    shape: 'Seed Bead',
    type: 'Earring',
    color: '',
  },
  'miyuki-necklace': {
    brand: 'Miyuki',
    shape: 'Seed Bead',
    type: 'Necklace',
    color: '',
  },
  'preciosa-seed-bead-bracelet': {
    brand: 'Preciosa',
    shape: 'Seed Bead',
    type: 'Bracelet',
    color: '',
  },
  'preciosa-tcb-bracelet': {
    brand: 'Preciosa',
    shape: 'Table Cut',
    type: 'Bracelet',
    color: '',
  },
  'preciosa-earring': {
    brand: 'Preciosa',
    shape: 'Seed Bead',
    type: 'Earring',
    color: '',
  },
  'preciosa-necklace': {
    brand: 'Preciosa',
    shape: 'Seed Bead',
    type: 'Necklace',
    color: '',
  },
  'londonderry-bracelet': {
    brand: 'Londonderry',
    shape: 'Various',
    type: 'Bracelet',
    color: '',
  },
  'londonderry-earring': {
    brand: 'Londonderry',
    shape: 'Various',
    type: 'Earring',
    color: '',
  },
  'londonderry-necklace': {
    brand: 'Londonderry',
    shape: 'Various',
    type: 'Necklace',
    color: '',
  },

};

export default preselectedFilter;
