import React from 'react';
import OrderIcon from '../assets/img/order_icon.png';
import HandIcon from '../assets/img/handmade_icon.png';
import ShippingIcon from '../assets/img/freight_icon.png';
import PaymentIcon from '../assets/img/payment_icon.png';
import SalesIcon from '../assets/img/sales_icon.png';

function ProcessSection() {
  return (
    <div className="process-section">
      <div className="process-step">
        <img src={OrderIcon} alt="Step 1" />
        <p>Place your order</p>
      </div>
      <div className="arrow">&#8594;</div>
      <div className="process-step">
        <img src={HandIcon} alt="Step 2" />
        <p>Factory manufacture</p>
      </div>
      <div className="arrow">&#8594;</div>
      <div className="process-step">
        <img src={ShippingIcon} alt="Step 3" />
        <p>Shipping by air</p>
      </div>
      <div className="arrow">&#8594;</div>
      <div className="process-step">
        <img src={PaymentIcon} alt="Step 4" />
        <p>Payment upon receiving</p>
      </div>
      <div className="arrow">&#8594;</div>
      <div className="process-step">
        <img src={SalesIcon} alt="Step 5" />
        <p>Increase sales and reorder</p>
      </div>
    </div>
  );
}

export default ProcessSection;
