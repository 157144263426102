/* eslint-disable default-param-last */
const initialCartState = {
  cartItems: [],
};

const cartReducer = (state = initialCartState, action) => {
  switch (action.type) {
    case 'SET_CART_ITEMS':
      return {
        ...state,
        cartItems: action.payload,
      };
    case 'REMOVE_ITEM_FROM_CART':
      return {
        ...state,
        cartItems: state.cartItems.filter((item) => !(item.productId === action.payload.productId && item.size === action.payload.size)),
      };
    default:
      return state;
  }
};

export default cartReducer;
