/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
// eslint-disable-next-line indent
import React from 'react';
import FounderPicture from '../assets/img/founder.png';

function AboutPage() {
  return (
    <div className="about-page">
      <h1>About us</h1>
      <p>
        Ke Lou is an entrepreneur with a diverse background in accounting, computer science, and the fashion jewelry industry. Ke earned a bachelor's degree in accounting from the University of Southern California, followed by a pursuit of a computer science major at Dartmouth College.
      </p>
      <img className="about_image" src={FounderPicture} alt="Ke Lou" />
      <p>
        Originally from Yiwu, China, a city known for its mature fashion jewelry manufacturing industry, Ke brings valuable insights into the field. After residing in the United States for eight years, Ke has developed a passion for connecting with customers and fostering business growth. By offering competitive prices and delivering high-quality, fashionable products, Ke aims to cultivate strong relationships with business partners.
      </p>
      <p>
        With a keen eye for market trends, Ke ensures that our company stays ahead of the curve. By sourcing and providing fashion-forward products without compromising quality, Ke strives to meet the preferences of our customers.
      </p>
      <p>
        Under Ke Lou's leadership, our company is poised to make significant strides in the fashion jewelry industry. Through a customer-centric approach and a commitment to excellence, Ke aims to build a thriving business and create long-lasting partnerships.
      </p>
      <p>
        Join us on this exciting journey of growth and collaboration with Ke Lou as we continue to connect and thrive together.
      </p>
    </div>
  );
}

export default AboutPage;
