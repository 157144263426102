import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import MyCart from './mycart';
import MyOrder from './myorder';
import { sighOutUser } from '../constants/firebaseConfig';
import { clearUser } from '../actions';

function MyOrders() {
  const [activeView, setActiveView] = useState('cart'); // 'cart' or 'orders'
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSignOut = () => {
    // Add logic to handle sign out
    sighOutUser();
    dispatch(clearUser());
    navigate('/');
  };

  return (
    <div className="my-account">
      <div className="my-account-sidebar">
        <button
          className={`sidebar-link ${activeView === 'cart' ? 'active' : ''}`}
          onClick={() => setActiveView('cart')}
          type="button"
        >
          My Cart
        </button>
        <button
          className={`sidebar-link ${activeView === 'orders' ? 'active' : ''}`}
          onClick={() => setActiveView('orders')}
          type="button"
        >
          My Orders
        </button>
        <button
          className="sidebar-logout"
          onClick={() => handleSignOut()}
          type="button"
        >
          Sign Out
        </button>
      </div>
      <div className="my-account-content">
        {activeView === 'cart' && <MyCart />}
        {activeView === 'orders' && <MyOrder />}
      </div>
    </div>
  );
}

export default MyOrders;
