/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import OrderThumbnail from '../components/order_thumbnail';
import { fetchOrders } from '../constants/firebaseConfig';

function MyOrder() {
  const [orders, setOrderItems] = useState([]);

  useEffect(() => {
    fetchOrders().then((items) => {
      setOrderItems(items);
    });
  }, []); //

  return (
    <div>
      {orders.map((order) => (
        <OrderThumbnail key={order.documentId} order={order} />
        // console.log(order)
      ))}
    </div>
  );
}

export default MyOrder;
