const Colors = [
  'Turquoise',
  'Blue',
  'Green',
  'Gold',
  'Silver',
  'Multicolor',
  'White',
  'Red',
  'Others',
  'Purple',
];

export default Colors;
