import shipping from '../constants/shipping'; // Adjust the import path as needed

const calculateShipping = (cartItems) => {
  let totalShippingCost = shipping.base;
  let totalBracelets = 0;
  let totalNecklaces = 0;
  let totalEarrings = 0;

  cartItems.forEach((item) => {
    if (!item.type) { return; }

    if (item.type === 'Bracelet') {
      if (shipping.combo_quantity[item.productId]) {
        totalBracelets += item.quantity * shipping.combo_quantity[item.productId];
      } else {
        totalBracelets += item.quantity;
      }
    } else if (item.type === 'Necklace') {
      totalNecklaces += item.quantity;
    } else if (item.type === 'Earring') {
      totalEarrings += item.quantity;
    } else {
      totalBracelets += item.quantity;
    }
  });

  const calculateCost = (total, rates) => {
    if (total < 100) return total * rates['<100'];
    if (total < 500) return total * rates['>100'];
    return total * rates['>500'];
  };

  totalShippingCost += calculateCost(totalBracelets, shipping.bracelet);
  totalShippingCost += calculateCost(totalNecklaces, shipping.necklace);
  totalShippingCost += calculateCost(totalEarrings, shipping.earring);

  console.log('Total shipping cost:', totalShippingCost.toFixed(2));
  console.log('Total bracelets:', totalBracelets);
  console.log('Total necklaces:', totalNecklaces);
  console.log('Total earrings:', totalEarrings);

  return totalShippingCost.toFixed(2);
};

export default calculateShipping;
