import React from 'react';
import { Link } from 'react-router-dom';
import InstagramIcon from '../assets/img/instagram.svg';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-column">
        <h3>Links</h3>
        <ul className="footer-list">
          <li><Link to="/about" className="footer-text">About</Link></li>
          <li><Link to="/about" className="footer-text">Tradeshow</Link></li>
          <li>
            <div className="footer-text">
              Social Media
            </div>
            <a href="https://www.instagram.com/londonderryjewelry/" target="_blank" rel="noopener noreferrer">
              <img src={InstagramIcon} alt="Instagram" className="social-media-icon" />
            </a>
          </li>
        </ul>
      </div>
      <div className="footer-column">
        <h3>Contact Info</h3>
        <p>30 N Gould St Ste R, Sheridan, WY 82801, United States</p>
        <p>Email: Sales@LondonderryFashion.com</p>
        <p>Phone: (213) 204-0541</p>
      </div>
    </footer>
  );
}

export default Footer;
