const shipping = {
  base: 22,
  bracelet: {
    '<100': 0.17, // "<100" means "less than 100"
    '>100': 0.15,
    '>500': 0.12,
  },
  necklace: {
    '<100': 0.25,
    '>100': 0.23,
    '>500': 0.20,
  },
  earring: {
    '<100': 0.14, // "<100" means "less than 100"
    '>100': 0.12,
    '>500': 0.10,
  },
  combo_quantity: {
    Combo01: 180,
    Combo02: 100,
    Combo03: 40,
  },
};

export default shipping;
