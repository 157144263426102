/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
// ProductThumbnail.js
import React, { useState } from 'react';
import { getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router';
import DefaultSizes from '../constants/sizes';
import { addToCart } from '../constants/firebaseConfig';
import SizeToPrice from '../constants/size_price';

function ProductThumbnail({ product }) {
  const findInitialSize = () => {
    for (const size of DefaultSizes) {
      if (product.sizesAvailable.includes(size)) {
        return size;
      }
    }
    return DefaultSizes[0]; // Fallback to the first default size if none match
  };

  const [quantity, setQuantity] = useState(0);
  const [selectedSize, setSelectedSize] = useState(findInitialSize());
  const navigate = useNavigate();

  // const handleAdd = () => {
  //   if (product.productId.includes('Combo')) {
  //     setQuantity(quantity + 1);
  //   } else {
  //     setQuantity(quantity + 6);
  //   }
  // };

  // const handleMinus = () => {
  //   if (product.productId.includes('Combo')) {
  //     if (quantity >= 1) {
  //       setQuantity(quantity - 1);
  //     }
  //   } else if (quantity >= 6) {
  //     setQuantity(quantity - 6);
  //   }
  // };

  const handleAdd = () => {
    const isLondonderry = product.brand === 'Londonderry';
    const incrementValue = isLondonderry && quantity < 18 ? 18 : 6;

    if (product.productId.includes('Combo')) {
      setQuantity(quantity + 1);
    } else {
      setQuantity(quantity + incrementValue);
    }
  };

  const handleMinus = () => {
    const isLondonderry = product.brand === 'Londonderry';
    const decrementValue = isLondonderry && quantity <= 18 ? 18 : 6;

    if (product.productId.includes('Combo')) {
      if (quantity >= 1) {
        setQuantity(quantity - 1);
      }
    } else if (quantity >= decrementValue) {
      setQuantity(quantity - decrementValue);
    }
  };

  const handleSizeChange = (event) => {
    setSelectedSize(event.target.value);
  };

  // userId, productId, quantity, size, price
  const handleAddToCart = () => {
    if (quantity === 0) {
      alert('Please select quantity');
      return;
    }

    const auth = getAuth();
    const user = auth.currentUser;
    if (!user) {
      navigate('/login');
      return;
    }
    // Logic to handle adding product to cart
    console.log('Added to cart:', product.productId);
    const price = SizeToPrice[selectedSize] * product.price.toFixed(2);
    addToCart(user.uid, product.productId, quantity, selectedSize, price, product.imageUrl, product.type)
      .then(() => {
        console.log('Added to cart successfully');
        setQuantity(0);
        setSelectedSize(findInitialSize());
      });
  };

  return (
    <div className="product-thumbnail">
      <div className="product-thumbnail-image-container">
        <img className="product-thumbnail-image" src={product.imageUrl} alt={product.productId} />
      </div>
      <div className="product-thumbnail-info-container">
        <div className="product-thumbnail-price-container">
          <div className="product-thumbnail-title">{product.productId}</div>

          {/* Quantity Controls */}
          <div className="quantity-controls">
            <button className="quantity_button" onClick={handleMinus} type="button">-</button>
            <span className="quantity_text">{quantity}</span>
            <button className="quantity_button" onClick={handleAdd} type="button">+</button>
          </div>
        </div>

        <div className="size-selection">
          <label htmlFor="size">Size:</label>
          <select id="size" value={selectedSize} onChange={handleSizeChange}>
            {product.sizesAvailable.map((size) => (
              <option key={size} value={size}>{size}</option>
            ))}
          </select>
        </div>

        <div className="product-thumbnail-cart-container">
          <div className="product-thumbnail-price">${(product.price * SizeToPrice[selectedSize]).toFixed(2)}</div>
          <button type="button" className="add-to-cart-btn" onClick={handleAddToCart}>
            Add to Cart
          </button>
        </div>

      </div>
    </div>
  );
}

export default ProductThumbnail;
