/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import SizeToPrice from '../constants/size_price';

function CartThumbnail({ item, onRemove }) {
  // const [quantity, setQuantity] = useState(item.quantity);

  // const handleQuantityChange = (e) => {
  //   setQuantity(e.target.value);
  //   // Additional logic to update quantity in cart
  // };

  return (
    <div className="cart-thumbnail">
      <div className="cart-image-container">
        <img src={item.imageUrl} alt={item.productId + item.size} className="cart-image" />
      </div>
      <div className="cart-detail">Product #: {item.productId}</div>
      <div className="cart-detail">Size: {item.size}</div>
      <div className="cart-detail">Price: ${(item.price * SizeToPrice[item.size]).toFixed(2)}</div>
      <div className="cart-detail">Quantity: {item.quantity}</div>
      <button
        className="cart-detail cart-remove"
        aria-label="Delete"
        type="button"
        onClick={() => onRemove(item.productId, item.size)}
      >&times;
      </button>
      {/* <div className="cart-detail">
        <input
          type="number"
          value={quantity}
          onChange={handleQuantityChange}
          className="quantity-input"
        />
      </div> */}
    </div>
  );
}

export default CartThumbnail;
