/* eslint-disable no-unused-vars */
// the starting point for your redux store
// this defines what your store state will look like
import { combineReducers } from 'redux';
import UserReducer from './user-reducer';
import CartReducer from './cart-reducer';

const rootReducer = combineReducers({
  user: UserReducer,
  cart: CartReducer,
});

export default rootReducer;
