/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router';
import ComboImage from '../assets/img/combo_image.png';
import Sizes from '../constants/sizes';
import { addToCart, fetchCombo } from '../constants/firebaseConfig';

function ComboPage() {
  const [selectedCombo, setSelectedCombo] = useState('');
  const [selectedSize, setSelectedSize] = useState(Sizes[0]);
  const navigate = useNavigate();
  const handleComboChange = (e) => {
    setSelectedCombo(e.target.value);
  };

  const handleSizeChange = (e) => {
    setSelectedSize(e.target.value);
  };

  const handleAddToCart = () => {
    const auth = getAuth();
    const user = auth.currentUser;
    // console.log('user', user);
    if (!user) {
      navigate('/login');
      return;
    }

    if (!selectedCombo) {
      alert('Please select a combo');
      return;
    }
    // Logic to handle adding product to cart
    fetchCombo(selectedCombo).then((comboItems) => {
      const product = comboItems[0];
      console.log('Combo fetched:', product);
      addToCart(user.uid, product.productId, 1, selectedSize, product.price, product.imageUrl, product.type)
        .then(() => {
          console.log('Added to cart successfully');
          setSelectedCombo('');
          setSelectedSize(Sizes[0]);
        });
      navigate('/account', { replace: true });
    });
  };

  return (
    <div className="combo-page">
      <h1>Combo Offers</h1>
      <p>Want a quick start? Order Tila Combo now!</p>
      <img src={ComboImage} alt="Combo Pricing" />

      <div className="combo-selection">
        <select value={selectedCombo} onChange={handleComboChange}>
          <option value="">Select a Combo</option>
          <option value="Combo01">Combo 1</option>
          <option value="Combo02">Combo 2</option>
          <option value="Combo03">Combo 3</option>
        </select>
      </div>

      <div className="combo-selection">
        <select value={selectedSize} onChange={handleSizeChange}>
          <option value="">Select a Size</option>
          <option value="5 inch">5 inch</option>
          <option value="6 inch">6 inch</option>
          <option value="7 inch">7 inch</option>
        </select>
      </div>

      <button type="submit" onClick={handleAddToCart} className="add-to-cart-btn">Add to Cart</button>
    </div>
  );
}

export default ComboPage;
