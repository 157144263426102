/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getAuth } from 'firebase/auth';
import { signInUser } from '../constants/firebaseConfig';
import { setUser } from '../actions';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();

  // const auth = getAuth();
  const navigate = useNavigate();
  const handleLogin = async (e) => {
    e.preventDefault();
    await signInUser(email, password);
    // dispatch(setUser(auth.currentUser));
    const auth = getAuth();
    const user = auth.currentUser.email;
    if (user) {
      dispatch(setUser(user));
      navigate('/');
    }
  };

  return (
    <div className="auth-form">
      <h2>Login</h2>
      <form onSubmit={handleLogin}>
        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" />
        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" />
        <button type="submit">Login</button>
        <div className="register-link">
          Not a member? <Link to="/register">Register</Link>
        </div>
      </form>
    </div>
  );
}

export default Login;
