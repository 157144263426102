/* eslint-disable react/button-has-type */
import React from 'react';
import { useNavigate } from 'react-router';
import FactoryImage from '../assets/img/factory_image.jpg';

function CompanySection() {
  const navigate = useNavigate();

  const handleLearnMore = () => {
    navigate('/about');
  };

  return (
    <div className="company_section_container">
      <div className="company_text_section">
        <h2>Why us</h2>
        <p>
          We are the sole importer of Japanese Miyuki Glass beads in mainland China.
        </p>
        <p>
          We operate two brick-and-mortar shops where we sell Miyuki Glass Beads and Czech Preciosa Beads,
          along with a factory where these beads are transformed into bracelets, earrings, and necklaces in China.
        </p>
        <button type="button" onClick={handleLearnMore} className="home_button">Learn More</button>
      </div>
      <div className="company_image_section">
        <img className="company_image" src={FactoryImage} alt="Company" />
      </div>
    </div>
  );
}

export default CompanySection;
