const sizeToPrice = {
  '5 inch': 1.0,
  '6 inch': 1.0,
  '7 inch': 1.15,
  '14 inch': 1.0,
  '15 inch': 1.0,
  '16 inch': 1.0,
  '17 inch': 1.1,
  '18 inch': 1.15,
  '28 inch': 1.0,
  '30 inch': 1.0,
  '32 inch': 1.0,
  '34 inch': 1.1,
  '38 inch': 1.25,
};

export default sizeToPrice;
